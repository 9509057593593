var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "main-footer" }, [
    _c("section", { staticClass: "footer" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c("p", [_vm._v("© Todos os direitos reservados")]),
          _vm._v(" "),
          _c("a", { attrs: { href: "/" } }, [
            _vm.url
              ? _c("img", {
                  staticClass: "logo-vertical",
                  attrs: {
                    src: _vm.url + "/img/logo-footer.png",
                    alt: "MEO Empresas"
                  }
                })
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }