<template>
    <footer class="main-footer">
        <section class="footer">
            <div class="row">
                <div class="col">
                    <p>© Todos os direitos reservados</p>
                    <a href="/">
                        <img v-if="url" :src="url + '/img/logo-footer.png'" alt="MEO Empresas" class="logo-vertical" />
                    </a>
                </div>
            </div>
        </section>
    </footer>
</template>

<script>
import * as Constants from '../store/constants'

export default {
  name: 'ae-footer',
  data () {
    return {
      url: ''
    }
  },
  created () {
    this.url = Constants.BASEURL
  }
}
</script>

<style lang="scss">
footer {
    background-color: #333333;
    padding: 20px 0;
    // position: absolute;
    bottom:0;
    width:100%;
}
footer p {
    float: left;
    margin: 0;
    padding: 0;
    color: #b9b9b9;
    font-size: 12px;
    font-size: 1.2rem;
    font-weight: 400;
}

footer .logo-vertical {
    float: right;
    width: 60px;
}

.main-footer .row .col {
    padding-left: 15px;
    padding-right: 15px;
}

.main-footer .row .col ul > li {
    display: inline-block;
}
.main-footer {
    grid-area: "footer";
}
.main-footer a {
    float: left;
    margin: 0;
    padding: 0;
    color: #b9b9b9;
    text-decoration: none;
    font-size: 11px;
    font-weight: 400;
    margin: 10px
}

.main-footer a:hover,
.main-footer a:focus {
    color: #fff;
}
.row {
    margin: auto;
    display: inline-block;
    width: 100%;
}
@media screen and (max-width: 764px) {
    footer .logo-vertical {
        display: none;
    }
}
footer p {
    float: left;
    margin: 0;
    padding: 0;
    color: #b9b9b9;
    font-size: 12px;
    font-weight: 400;
    margin: 0 10px 0 10px;
}

footer .logo-vertical {
    float: right;
    width: 40px;
    height: auto;
    margin-top: -15px;
}

.col a {
    float: right;
}
</style>
