var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.data.hide
    ? _c(
        "div",
        {
          staticClass: "app-button",
          on: {
            click: function($event) {
              _vm.model === "url" ? _vm.clickMe() : _vm.clickMe(_vm.data)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "app" },
            [
              !_vm.custom.includes(_vm.data.icon)
                ? _c("md-icon", { staticClass: "icon-app" }, [
                    _vm._v(_vm._s(_vm.data.icon))
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) &&
              _vm.data.icon === "public-information"
                ? _c("img", {
                    staticClass: "icon",
                    attrs: { src: "/icons/Portal-projetos-icon.png" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) &&
              _vm.data.icon === "public-information"
                ? _c("img", {
                    staticClass: "white-icon",
                    attrs: { src: "/icons/White-portal-projetos-icon.png" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) &&
              _vm.data.icon === "dashboards"
                ? _c("img", {
                    staticClass: "icon",
                    attrs: { src: "/icons/Portal-dashboard-icon.png" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) &&
              _vm.data.icon === "dashboards"
                ? _c("img", {
                    staticClass: "white-icon",
                    attrs: { src: "/icons/White-portal-dashboard-icon.png" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) &&
              _vm.data.icon === "service-pack"
                ? _c("img", {
                    staticClass: "icon",
                    attrs: { src: "/icons/Portal-webnizado-icon.png" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) &&
              _vm.data.icon === "service-pack"
                ? _c("img", {
                    staticClass: "white-icon",
                    attrs: { src: "/icons/White-portal-webnizado-icon.png" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) && _vm.data.icon === "tickets"
                ? _c("img", {
                    staticClass: "icon",
                    attrs: { src: "/icons/Portal-microstrategy-icon.png" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) && _vm.data.icon === "tickets"
                ? _c("img", {
                    staticClass: "white-icon",
                    attrs: { src: "/icons/White-portal-microstrategy-icon.png" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) && _vm.data.icon === "reports"
                ? _c("img", {
                    staticClass: "icon",
                    attrs: { src: "/icons/Portal-report-icon.png" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) && _vm.data.icon === "reports"
                ? _c("img", {
                    staticClass: "white-icon",
                    attrs: { src: "/icons/White-portal-report-icon.png" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) && _vm.data.icon === "cadastro"
                ? _c("img", {
                    staticClass: "icon",
                    attrs: { src: "/icons/cadastro-icon-gra.svg" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) && _vm.data.icon === "cadastro"
                ? _c("img", {
                    staticClass: "white-icon",
                    attrs: { src: "/icons/White-cadastro-icon-gra.svg" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) &&
              _vm.data.icon === "ca-catalog"
                ? _c("img", {
                    staticClass: "icon",
                    attrs: { src: "/icons/catalogo-icon-gra.svg" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) &&
              _vm.data.icon === "ca-catalog"
                ? _c("img", {
                    staticClass: "white-icon",
                    attrs: { src: "/icons/White-catalogo-icon-gra.svg" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) && _vm.data.icon === "backups"
                ? _c("img", {
                    staticClass: "icon",
                    attrs: { src: "/icons/backup-remoto.png" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) && _vm.data.icon === "backups"
                ? _c("img", {
                    staticClass: "white-icon",
                    attrs: { src: "/icons/White-backup-remoto.png" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) &&
              _vm.data.icon === "reporting"
                ? _c("img", {
                    staticClass: "icon",
                    attrs: { src: "/icons/management-solutions.png" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) &&
              _vm.data.icon === "reporting"
                ? _c("img", {
                    staticClass: "white-icon",
                    attrs: { src: "/icons/White-management-solutions.png" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) &&
              _vm.data.icon === "invoicing"
                ? _c("img", {
                    staticClass: "icon",
                    attrs: { src: "/icons/documentos-financeiros-icon.png" }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.custom.includes(_vm.data.icon) &&
              _vm.data.icon === "invoicing"
                ? _c("img", {
                    staticClass: "white-icon",
                    attrs: {
                      src: "/icons/White-documentos-financeiros-icon.png"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.data.name))])
            ],
            1
          ),
          _vm._v(" "),
          String(this.data.base).startsWith("https://")
            ? _c(
                "div",
                { staticClass: "app-in-new" },
                [_c("md-icon", [_vm._v("open_in_new")])],
                1
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }