<template>
  <div id="ae-header">
    <nav>
      <div class="nav-menu">
        <a :href="logo.url">
          <img v-if="logo.img" :src="logo.img" :alt="logo.alt" width="100" height="30" class="logo-horizon" />
        </a>
        <div class="nav-links">
          <a class="showloader" v-click-outside="hide" v-for="(item, index) in menu" :key="index" :href="item.url" @click="toggle(index, item)">
            <span v-if="item.type === 'text'">{{ item.name }}</span>
            <span v-if="item.type === 'icon'"><md-icon class="icon-menu">{{ item.name }}</md-icon></span>
          </a>
        </div>
        <div class="nav-links">
          <a v-click-outside="hide" href="#" v-for="(item, index) in menuMobile" :key="item.name + index" class="nav-links-icon icon" @click="toggleMobile(index, item)">
            <span v-if="item.type === 'text'">{{ item.name }}</span>
            <span v-if="item.type === 'icon'"><md-icon class="icon-menu">{{ item.name }}</md-icon></span>
            <span v-if="item.type === 'custom-icon'"><md-icon class="icon-menu" :md-src="item.name"></md-icon></span>
          </a>
        </div>
      </div>
    </nav>
    <div v-for="(item, index) in menu" :key="index + item.name">
      <div v-if="item.toggle == item.name && item.children && item.children.length > 0">
        <div class="dropdown-content-mobile">
          <div v-for="(value, index) in item.children" :key="index">
            <div class="item-menu-mobile">
              <a :href="value.url" class="item-dropdown" v-if="value.type === 'line'" >
                <span class="settings-text">{{ value.name }}</span>
                <md-icon class="settings-icon">keyboard_arrow_right</md-icon>
              </a>
              <div v-if="value.type === 'app'">
                <div v-for="(v, i) in value.apps" :key="i">
                  <md-bottom-bar class="md-button-bar-altice" v-if="value.type === 'app'" md-sync-route>
                    <button :class="{ 'menu-button': true, 'background-button-menu': url.includes(v[0].url) }" type="button" @click="go(v[0].url)">
                      <div class="ripple">
                        <div class="button-content">
                          <md-icon>{{ v[0].icon }}</md-icon>
                          <span>{{ v[0].name }}</span>
                        </div>
                      </div>
                    </button>
                    <button :class="{ 'menu-button': true, 'background-button-menu': url.includes(v[1].url) }" type="button" v-if="v[1]" @click="go(v[1].url)">
                      <div class="ripple">
                        <div class="button-content">
                          <md-icon>{{ v[1].icon }}</md-icon>
                          <span>{{ v[1].name }}</span>
                        </div>
                      </div>
                    </button>
                    <button type="button" class="menu-button" v-if="!v[1]">
                      <div class="ripple">
                        <div class="button-content">
                          <md-icon></md-icon>
                          <span></span>
                        </div>
                      </div>
                    </button>
                  </md-bottom-bar>
                </div>
              </div>
              <!-- <md-bottom-bar class="md-button-bar-altice" v-if="value.type === 'app'" md-sync-route>
                <button type="button" class="menu-button" :to="value.apps[0].url">
                  <div class="ripple">
                    <div class="button-content">
                      <md-icon>{{ value.apps[0].icon }}</md-icon>
                      <span>{{ value.apps[0].name }}</span>
                    </div>
                  </div>
                </button>
                <button type="button" class="menu-button" v-if="value.apps[1]">
                  <div class="ripple">
                    <div class="button-content">
                      <md-icon>{{ value.apps[1].icon }}</md-icon>
                      <span>{{ value.apps[1].name }}</span>
                    </div>
                  </div>
                </button>
                <button type="button" class="menu-button" v-if="!value.apps[1]">
                  <div class="ripple">
                    <div class="button-content">
                      <md-icon></md-icon>
                      <span></span>
                    </div>
                  </div>
                </button>
              </md-bottom-bar> -->
            </div>
            <hr>
          </div>
        </div>
      </div>
    </div>
    <div v-for="(item, index) in menuMobile" :key="index + item.name">
      <div v-if="item.toggle == item.name && item.children && item.children.length > 0">
        <div class="dropdown-content-mobile">
          <div v-for="(value, index) in item.children" :key="index">
            <div class="item-menu-mobile">
              <a :href="value.url" class="item-dropdown" v-if="value.type === 'line'" >
                <span class="settings-text">{{ value.name }}</span>
                <md-icon class="settings-icon">keyboard_arrow_right</md-icon>
              </a>
              <div v-if="value.type === 'app'">
                <div v-for="(v, i) in value.apps" :key="i">
                  <md-bottom-bar class="md-button-bar-altice" v-if="value.type === 'app'" md-sync-route>
                    <button :class="{ 'menu-button': true, 'background-button-menu': url.includes(v[0].url) }" type="button" @click="go(v[0].url)">
                      <div class="ripple">
                        <div class="button-content">
                          <md-icon>{{ v[0].icon }}</md-icon>
                          <span>{{ v[0].name }}</span>
                        </div>
                      </div>
                    </button>
                    <button :class="{ 'menu-button': true, 'background-button-menu': url.includes(v[1].url) }" type="button" v-if="v[1]" @click="go(v[1].url)">
                      <div class="ripple">
                        <div class="button-content">
                          <md-icon>{{ v[1].icon }}</md-icon>
                          <span>{{ v[1].name }}</span>
                        </div>
                      </div>
                    </button>
                    <button type="button" class="menu-button" v-if="!v[1]">
                      <div class="ripple">
                        <div class="button-content">
                          <md-icon></md-icon>
                          <span></span>
                        </div>
                      </div>
                    </button>
                  </md-bottom-bar>
                </div>
              </div>
            </div>
            <hr>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from 'vue-router'
import ClickOutside from 'vue-click-outside'
import * as Constants from '../store/constants'

export default {
  name: 'ae-header',
  data: () => {
    return {
      custom: [
        'public-information',
        'dashboards',
        'tickets',
        'service-pack',
        'reports',
        'invoicing'
      ],
      clickIn: false,
      url: window.location.pathname
    }
  },
  directives: {
    ClickOutside
  },
  props: {
    logo: {
      type: Object,
      default: () => {
        return {
          url: '/dashboard',
          img: Constants.BASEURL + '/img/logo-altice-empresas-h.png',
          alt: 'MEO Empresas Empresas'
        }
      }
    },
    menu: {
      type: Array,
      default: () => {
        return [
          {
            url: '/login',
            type: 'text',
            name: 'Login',
            toggle: false
          },
          {
            url: '/profile',
            type: 'icon',
            name: 'person',
            toggle: false
          },
          {
            url: '#',
            type: 'text',
            name: 'Add On',
            toggle: false,
            children: [
              {
                url: '/settings',
                type: 'line',
                name: 'Configurações'
              },
              {
                url: '/logout',
                type: 'line',
                name: 'Logout'
              },
              {
                url: '#',
                type: 'app',
                apps: [
                  {
                    url: '/public-information',
                    icon: 'connect_without_contact',
                    name: 'Public Information'
                  }
                ]
              }
            ]
          }
        ]
      }
    },
    menuMobile: {
      type: Array,
      default: () => {
        return [
          {
            toggle: '#',
            type: 'icon',
            name: 'menu'
          }
        ]
      }
    }
  },
  mounted () {
    // prevent click outside event with popupItem.
    this.popupItem = this.$el
  },
  methods: {
    hide () {
      if (!this.clickIn){
        this.menu.forEach((element, ind) => {
          this.menu[ind].toggle = false
        })
      }
    },
    go (url) {
      window.location.pathname = url
    },
    toggle (index, item) {
      this.clickIn = true
      if (item.url === '#') {
        if (this.menu[index].toggle === item.name) {
          this.menu[index].toggle = false
        } else {
          this.menu.forEach((element, ind) => {
            this.menu[ind].toggle = false
          })
          this.menu[index].toggle = item.name
        }
      }
      if (item.url === '#' && typeof item.click === 'function') {
        item.click()
      }
      setTimeout(() => {
        this.clickIn = false
      }, 10)
    },
    toggleMobile (index, item) {
      this.clickIn = true
      if (item.url === '#') {
        if (this.menuMobile[index].toggle === item.name) {
          this.menuMobile[index].toggle = false
        } else {
          this.menuMobile.forEach((element, ind) => {
            this.menuMobile[ind].toggle = false
          })
          this.menuMobile[index].toggle = item.name
        }
      }
      if (item.url === '#' && typeof item.click === 'function') {
        item.click()
      }
      setTimeout(() => {
        this.clickIn = false
      }, 10)
    }
  }
}
</script>

<style lang="scss" scope>
#ae-header {
  .md-theme-default a:not(.md-button) {
    color: #fff !important;
    text-decoration: none !important;
  }
  .md-theme-default a:not(.md-button):hover {
    color: #448aff !important;
    text-decoration: none !important;
  }
  .nav-menu {
    overflow: hidden;
    background-color: #000;
    padding-left: 0;
    padding-right: 0;
  }
  .nav-menu .nav-links {
    float: right;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .nav-menu .nav-links a,
  .nav-menu .nav-links .btn-drop {
    border-bottom: 5px solid transparent;
    display: inline-block;
    padding: 22px;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-size: 1.0rem;
    line-height: 20px;
    line-height: 2rem;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #000;
    color: #fff;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  .nav-menu .nav-links a:hover,
  .nav-menu .nav-links a:focus,
  .nav-menu .nav-links .btn-drop:hover,
  .nav-menu .nav-links .btn-drop:focus {
    border-bottom: 5px solid #007de8;
    background-color: #000;
    color: #fff;
  }

  .nav-menu .nav-links .dropdown {
    overflow: hidden;
  }

  .nav-menu .nav-links .dropdown .btn-drop {
    border: none;
    border-bottom: 5px solid transparent;
  }

  .nav-menu .nav-links .dropdown .btn-drop:hover,
  .nav-menu .nav-links .dropdown .btn-drop:focus {
    border-bottom: 5px solid #007de8;
    background-color: #eeeeee;
    color: #007de8;
  }

  .nav-menu .nav-links .dropdown-content {
    position: absolute;
    left: 0;
    z-index: 25;
    border-color: #ccc;
    border-style: solid;
    border-width: 1px 0 1px 0;
    display: none;
    margin: 0 auto;
    width: 100%;
    background-color: #ffffff;
    text-align: right;
    -webkit-transition: 0.3s;
    transition: 0.3s;
  }

  @media screen and (max-width: 767px) {
    .nav-menu .nav-links .dropdown-content {
      border-width: 0;
    }
  }

  .nav-menu .nav-links .dropdown-content a {
    float: none;
  }

  .nav-menu .nav-links .dropdown-content a:hover,
  .nav-menu .nav-links .dropdown-content a:focus {
    border-bottom: 5px solid transparent;
    background-color: #ffffff;
    color: #007de8;
  }

  @media screen and (max-width: 767px) {
    .nav-menu .nav-links .dropdown-content a {
      padding-left: 50px;
    }
  }

  .nav-menu a.icon {
    display: none;
  }

  /* When the screen is less than 600 pixels wide, hide all links, except for the first one ("Home"). Show the link that contains should open and close the nav-menu (.icon) */
  @media screen and (max-width: 767px) {
    .nav-menu .nav-links a,
    .nav-menu .nav-links .dropdown .btn-drop {
      display: none;
    }

    .nav-menu a.icon {
      float: right;
      display: block;
    }
  }

  @media screen and (max-width: 767px) {
    .nav-menu.responsive {
      position: relative;
    }

    .nav-menu.responsive a.icon {
      border: none;
      position: absolute;
      right: 0;
      top: 0;
    }

    .nav-menu.responsive a.icon:hover,
    .nav-menu.responsive a.icon:focus {
      border: none;
    }
    .nav-menu.responsive .nav-links {
      float: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      position: initial;
      margin-top: 10px;
    }

    .nav-menu.responsive .nav-links a,
    .nav-menu.responsive .nav-links .btn-drop {
      float: none;
      display: block;
      text-align: left;
    }

    .nav-menu.responsive .dropdown {
      float: none;
    }

    .nav-menu.responsive .nav-links .dropdown-content {
      position: relative;
    }

    .nav-menu.responsive .nav-links .dropdown-content a {
      border: none;
      border-bottom: 1px solid #ccc;
      background-color: #ffffff;
    }

    .nav-menu.responsive .dropdown .btn-drop {
      display: block;
      width: 100%;
      text-align: left;
    }
  }

  .logo-horizon {
    float: left;
    margin: 0 15px 0;
    width: 100px;
  }
  @media screen and (max-width: 767px) {
    .logo-horizon {
      float: none;
    }
  }
  .icon-menu {
    padding: 0px;
    color: #fff !important;
    &:hover,
    &:focus {
      color: #0084d6 !important;
    }
  }
  .dropdown-content-mobile {
    position: absolute;
    right: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: 0px;
    padding: 0;
    z-index: 999;
    @media screen and (min-width: 480px){
      max-width: 320px;
    }
  }
  .item-menu-mobile {
    font-weight: 600;
    text-transform: none;
    text-align: left;
    text-decoration: none !important;
    color: #000 !important;
    font-size: 12px;
    // padding: 10px;

    &:hover,
    &:focus {
      color: #0084d6 !important;
    }

    a {
      display: block;
      padding: 12px 16px;
    }
  }
  .item-dropdown {
    position: relative;
    height: 48px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: left;
    text-decoration: none !important;
    color: #000 !important;
    font-size: 10px;
    &:hover,
    &:focus {
      color: #0084d6 !important;
      .md-icon.md-theme-default.md-icon-font {
        color: #0084d6;
      }
    }
    .md-icon.md-theme-default.md-icon-font {
      color: #000;
      font-size: 14px !important;
      padding-bottom: 2px;
    }
  }
  .settings-text {
    color: #000 !important;
    position: absolute !important;
    top: 19px !important;
  }
  .settings-text:hover {
    color: #0084d6 !important;
    position: absolute !important;
    top: 19px !important;
  }
  .settings-icon {
    position: absolute !important;
    right: 16px !important;
    top: 13px !important;
  }
  hr {
    margin: 0 !important;
  }
  // APP
  .menu-button {
    background-color: white;
    height: 80px !important;
    color: black;
    margin: 0;
    flex: 1;
    border-radius: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1em;
    text-transform: none;
    padding: 0;
    display: inline-block;
    position: relative;
    overflow: hidden;
    outline: none;
    border: 0;
    font-family: inherit;
    text-decoration: none;
    vertical-align: top;
    white-space: nowrap;

    @media screen and (min-width: 480px){
      min-width: 80px;
      max-width: 168px;
    }
  }
  .menu-button {
    border-right: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb !important;
  }
  .md-bottom-bar:not(:last-child) {
    border-bottom: 1px solid #ebebeb !important;
  }
  .ripple {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 5;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px 10px;
    transition: color .4s cubic-bezier(.4,0,.2,1);

    &:hover {
      color: #0084d6 !important;
      .md-icon {
        color:  #0084d6 !important;
      }
    }
  }
  .button-content {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
    > .md-icon {
      color: black;
    }
  }
  .md-bottom-bar-item {
    height:80px !important;
    border-bottom:1px solid #ebebeb;
    .md-bottom-bar-icon {
      color:black !important;
    }
    span {
      color:black !important;
    }
  }

  .md-bottom-bar-item:first-child {
    border-right: 1px solid #ebebeb;
  }

  .md-bottom-bar-item:hover {
    background-color: #0084D6 !important;
    color:#fff !important;
    .md-bottom-bar-icon {
      color:#fff !important;
    }
    span{
      color:#fff !important;
    }
  }
  .menu-here{
    position: absolute;
    top: 0;
    right: 0;
    color: #0084D6;
  }
  .background-button-menu {
    background-color: #000;
    color: #fff;
    .md-icon {
      color: #fff;
    }
  }
}
</style>
