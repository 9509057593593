<template>
  <div v-if="!data.hide" class="app-button" @click="model === 'url' ? clickMe() : clickMe(data)">
    <div class="app">
      <md-icon v-if="!custom.includes(data.icon)" class="icon-app" >{{ data.icon }}</md-icon>
      <img v-if="custom.includes(data.icon) && data.icon === 'public-information'" class="icon" src="/icons/Portal-projetos-icon.png"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'public-information'" class="white-icon" src="/icons/White-portal-projetos-icon.png"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'dashboards'" class="icon" src="/icons/Portal-dashboard-icon.png"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'dashboards'" class="white-icon" src="/icons/White-portal-dashboard-icon.png"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'service-pack'" class="icon" src="/icons/Portal-webnizado-icon.png"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'service-pack'" class="white-icon" src="/icons/White-portal-webnizado-icon.png"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'tickets'" class="icon" src="/icons/Portal-microstrategy-icon.png"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'tickets'" class="white-icon" src="/icons/White-portal-microstrategy-icon.png"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'reports'" class="icon" src="/icons/Portal-report-icon.png"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'reports'" class="white-icon" src="/icons/White-portal-report-icon.png"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'cadastro'" class="icon" src="/icons/cadastro-icon-gra.svg"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'cadastro'" class="white-icon" src="/icons/White-cadastro-icon-gra.svg"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'ca-catalog'" class="icon" src="/icons/catalogo-icon-gra.svg"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'ca-catalog'" class="white-icon" src="/icons/White-catalogo-icon-gra.svg"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'backups'" class="icon" src="/icons/backup-remoto.png"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'backups'" class="white-icon" src="/icons/White-backup-remoto.png"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'reporting'" class="icon" src="/icons/management-solutions.png"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'reporting'" class="white-icon" src="/icons/White-management-solutions.png"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'invoicing'" class="icon" src="/icons/documentos-financeiros-icon.png"/>
      <img v-if="custom.includes(data.icon) && data.icon === 'invoicing'" class="white-icon" src="/icons/White-documentos-financeiros-icon.png"/>
      <p>{{ data.name }}</p>
    </div>
    <div class="app-in-new" v-if="String(this.data.base).startsWith('https://')">
      <md-icon>open_in_new</md-icon>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'

export default {
  name: 'my-app',
  props: {
    data: {
      type: Object,
      require: true
    },
    model: {
      type: String,
      default: 'url'
    }
  },
  methods: {
    clickMe (item={}) {
      console.log('item')
      console.log(item)
      this.$emit('selected', item)
      // if (!String(this.data.base).startsWith('https://')) {
      //   window.location.pathname = this.data.base
      // } else {
      //   Swal.fire({
      //     title: 'Será aberta nova janela no Browser!',
      //     text: 'Para voltar bastará fechar a nova janela.',
      //     showDenyButton: false,
      //     showCancelButton: false,
      //     confirmButtonText: 'Entrar'
      //   }).then((result) => {
      //     /* Read more about isConfirmed, isDenied below */
      //     if (result.isConfirmed) {
      //       window.open(this.data.base, '_blank')
      //     }
      //   })
      // }
    }
  },
  data () {
    return {
      custom: [
        'public-information',
        'dashboards',
        'tickets',
        'service-pack',
        'reports',
        'cadastro',
        'ca-catalog',
        'backups',
        'reporting',
        'invoicing'
      ]
    }
  }
}
</script>

<style lang="scss">
.icon-app {
  font-size: 69px !important;
  width: 69px;
  height: 69px;
  color: #000 !important;
}
.app-button {
  display: inline-flex;
  border: 2px solid #ccc;
  color: #000;
  border-radius: 0px;
  padding: 30px;
  margin: 0px 0px;
  .app .icon {
    display: inline-flex;
  }
  .app .white-icon {
    display: none;
  }

  cursor: pointer;

  @media screen and (max-width: 767px){
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:hover {
    .icon-app {
      color: #ffffff !important;
    }
    background-color: #007de8;
    color: white;
    .app .icon {
      display: none;
    }
    .app .white-icon {
      display: inline-block;
    }
    .app-in-new {
      color: white;
    }
  }
}
.app {
  width: 150px;
  height: 150px;
  > p {
    font-size: 20px;
    line-height: 29px;
    font-weight: 600;
    color: inherit;
  }
}
.app-in-new {
  position: relative;
}

.icon, .white-icon {
  height: 69px;
  width: 69px;
}
</style>
