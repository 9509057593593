<template>
  <div class="breadcrumbs">
    <span v-for="(item, i) in data" :key="i">
      <span class="arrow" v-if="i > 0 | ((i === (data.length - 1)) && i > 0)"> > </span>
      <a :href="item.link" class="link">{{item.label}}</a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    data: Array
  }
}
</script>

<style lang="scss">
.breadcrumbs{
  text-align: left;
  padding: 20px 0 20px 20px;
  // padding-left: 20px;
  font-size: 12px;
  font-weight: 200;
  .link{
    text-decoration: underline;
    cursor:pointer;
  }
  .arrow{
    text-decoration: none;
  }
}
</style>
