var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "ae-header" } },
    [
      _c("nav", [
        _c("div", { staticClass: "nav-menu" }, [
          _c("a", { attrs: { href: _vm.logo.url } }, [
            _vm.logo.img
              ? _c("img", {
                  staticClass: "logo-horizon",
                  attrs: {
                    src: _vm.logo.img,
                    alt: _vm.logo.alt,
                    width: "100",
                    height: "30"
                  }
                })
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "nav-links" },
            _vm._l(_vm.menu, function(item, index) {
              return _c(
                "a",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.hide,
                      expression: "hide"
                    }
                  ],
                  key: index,
                  staticClass: "showloader",
                  attrs: { href: item.url },
                  on: {
                    click: function($event) {
                      return _vm.toggle(index, item)
                    }
                  }
                },
                [
                  item.type === "text"
                    ? _c("span", [_vm._v(_vm._s(item.name))])
                    : _vm._e(),
                  _vm._v(" "),
                  item.type === "icon"
                    ? _c(
                        "span",
                        [
                          _c("md-icon", { staticClass: "icon-menu" }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "nav-links" },
            _vm._l(_vm.menuMobile, function(item, index) {
              return _c(
                "a",
                {
                  directives: [
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: _vm.hide,
                      expression: "hide"
                    }
                  ],
                  key: item.name + index,
                  staticClass: "nav-links-icon icon",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      return _vm.toggleMobile(index, item)
                    }
                  }
                },
                [
                  item.type === "text"
                    ? _c("span", [_vm._v(_vm._s(item.name))])
                    : _vm._e(),
                  _vm._v(" "),
                  item.type === "icon"
                    ? _c(
                        "span",
                        [
                          _c("md-icon", { staticClass: "icon-menu" }, [
                            _vm._v(_vm._s(item.name))
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  item.type === "custom-icon"
                    ? _c(
                        "span",
                        [
                          _c("md-icon", {
                            staticClass: "icon-menu",
                            attrs: { "md-src": item.name }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            }),
            0
          )
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.menu, function(item, index) {
        return _c("div", { key: index + item.name }, [
          item.toggle == item.name && item.children && item.children.length > 0
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "dropdown-content-mobile" },
                  _vm._l(item.children, function(value, index) {
                    return _c("div", { key: index }, [
                      _c("div", { staticClass: "item-menu-mobile" }, [
                        value.type === "line"
                          ? _c(
                              "a",
                              {
                                staticClass: "item-dropdown",
                                attrs: { href: value.url }
                              },
                              [
                                _c("span", { staticClass: "settings-text" }, [
                                  _vm._v(_vm._s(value.name))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "md-icon",
                                  { staticClass: "settings-icon" },
                                  [_vm._v("keyboard_arrow_right")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        value.type === "app"
                          ? _c(
                              "div",
                              _vm._l(value.apps, function(v, i) {
                                return _c(
                                  "div",
                                  { key: i },
                                  [
                                    value.type === "app"
                                      ? _c(
                                          "md-bottom-bar",
                                          {
                                            staticClass: "md-button-bar-altice",
                                            attrs: { "md-sync-route": "" }
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                class: {
                                                  "menu-button": true,
                                                  "background-button-menu": _vm.url.includes(
                                                    v[0].url
                                                  )
                                                },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.go(v[0].url)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "ripple" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "button-content"
                                                      },
                                                      [
                                                        _c("md-icon", [
                                                          _vm._v(
                                                            _vm._s(v[0].icon)
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(v[0].name)
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            v[1]
                                              ? _c(
                                                  "button",
                                                  {
                                                    class: {
                                                      "menu-button": true,
                                                      "background-button-menu": _vm.url.includes(
                                                        v[1].url
                                                      )
                                                    },
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.go(v[1].url)
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "ripple" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "button-content"
                                                          },
                                                          [
                                                            _c("md-icon", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  v[1].icon
                                                                )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  v[1].name
                                                                )
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !v[1]
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass: "menu-button",
                                                    attrs: { type: "button" }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "ripple" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "button-content"
                                                          },
                                                          [
                                                            _c("md-icon"),
                                                            _vm._v(" "),
                                                            _c("span")
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("hr")
                    ])
                  }),
                  0
                )
              ])
            : _vm._e()
        ])
      }),
      _vm._v(" "),
      _vm._l(_vm.menuMobile, function(item, index) {
        return _c("div", { key: index + item.name }, [
          item.toggle == item.name && item.children && item.children.length > 0
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "dropdown-content-mobile" },
                  _vm._l(item.children, function(value, index) {
                    return _c("div", { key: index }, [
                      _c("div", { staticClass: "item-menu-mobile" }, [
                        value.type === "line"
                          ? _c(
                              "a",
                              {
                                staticClass: "item-dropdown",
                                attrs: { href: value.url }
                              },
                              [
                                _c("span", { staticClass: "settings-text" }, [
                                  _vm._v(_vm._s(value.name))
                                ]),
                                _vm._v(" "),
                                _c(
                                  "md-icon",
                                  { staticClass: "settings-icon" },
                                  [_vm._v("keyboard_arrow_right")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        value.type === "app"
                          ? _c(
                              "div",
                              _vm._l(value.apps, function(v, i) {
                                return _c(
                                  "div",
                                  { key: i },
                                  [
                                    value.type === "app"
                                      ? _c(
                                          "md-bottom-bar",
                                          {
                                            staticClass: "md-button-bar-altice",
                                            attrs: { "md-sync-route": "" }
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                class: {
                                                  "menu-button": true,
                                                  "background-button-menu": _vm.url.includes(
                                                    v[0].url
                                                  )
                                                },
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.go(v[0].url)
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "ripple" },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "button-content"
                                                      },
                                                      [
                                                        _c("md-icon", [
                                                          _vm._v(
                                                            _vm._s(v[0].icon)
                                                          )
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(v[0].name)
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            v[1]
                                              ? _c(
                                                  "button",
                                                  {
                                                    class: {
                                                      "menu-button": true,
                                                      "background-button-menu": _vm.url.includes(
                                                        v[1].url
                                                      )
                                                    },
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.go(v[1].url)
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "ripple" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "button-content"
                                                          },
                                                          [
                                                            _c("md-icon", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  v[1].icon
                                                                )
                                                              )
                                                            ]),
                                                            _vm._v(" "),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  v[1].name
                                                                )
                                                              )
                                                            ])
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            !v[1]
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass: "menu-button",
                                                    attrs: { type: "button" }
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "ripple" },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "button-content"
                                                          },
                                                          [
                                                            _c("md-icon"),
                                                            _vm._v(" "),
                                                            _c("span")
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }),
                              0
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("hr")
                    ])
                  }),
                  0
                )
              ])
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }