import CryptoJS from 'crypto-js'
const p = "4y|/an2(IS&+Mw#9Z'z,PvEFrL@Afmf~~q8WMxSIrR*wY^Fp%hAcGrFAX3pjG0w"
const encrypt = (item) => {
  var ciphertext = CryptoJS.AES.encrypt(item, p).toString();
  return ciphertext
}
const decrypt = (item) => {
  try {
    var bytes  = CryptoJS.AES.decrypt(item, p);
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  } catch (e) {
    var decryptedData = item
    console.log(e)
  }
  return decryptedData
}
const state = {
  widget: {
    client: {
      name: ''
    }
  },
  state: {
    auth: {
      access: '',
      refresh: ''
    },
    expired: new Date().setHours(new Date().getHours() + 2),
    token: '',
    disclaimer: '',
    try: 0,
    tryEmail: '',
    internal: false
  },
  tickets: {
    session: ''
  },
  pm: {
    session: ''
  },
  publicInformation: {
    project: {}
  }
}
// const store = {}
if (!localStorage.getItem('app')) {
  localStorage.setItem('app', encrypt(JSON.stringify(state)))
}
const oldStore = decrypt(localStorage.getItem('app'))

const setAuth = (data) => {
  oldStore.state.auth = data.login
  oldStore.state.try = 0
  console.log(data)
  oldStore.state.token = data.access.id_token
  oldStore.expired = new Date().setHours(new Date().getHours() + 2)
  localStorage.setItem('app', encrypt(JSON.stringify(oldStore))) 
}
const resetStore = () => {
  const state = {
    widget: {
      client: {
        name: ''
      }
    },
    state: {
      auth: {
        access: '',
        refresh: ''
      },
      expired: new Date().setHours(new Date().getHours() + 2),
      token: '',
      disclaimer: '',
      try: 0,
      tryEmail: '',
      internal: false
    },
    tickets: {
      session: ''
    },
    pm: {
      session: ''
    },
    publicInformation: {
      project: {}
    }
  }
  localStorage.setItem('app', encrypt(JSON.stringify(state)))
}
const tryLogin = (data) => {
  if (oldStore.state.tryEmail === data) {
    oldStore.state.try += 1
  } else {
    oldStore.state.try = 0
    oldStore.state.tryEmail = data
  }
  localStorage.setItem('app', encrypt(JSON.stringify(oldStore)))
}

const setToken = (data) => {
  oldStore.token = data
  oldStore.expired = new Date().setHours(new Date().getHours() + 2)
  localStorage.setItem('app', encrypt(JSON.stringify(oldStore)))
}
const setDisclaimer = (data) => {
  oldStore.state.disclaimer = data
  localStorage.setItem('app', encrypt(JSON.stringify(oldStore)))
}
const setName = (data) => {
  oldStore.widget.client.name = data
  localStorage.setItem('app', encrypt(JSON.stringify(oldStore)))
}
const setTicketSession = (data) => {
  oldStore.tickets.session = data
  localStorage.setItem('app', encrypt(JSON.stringify(oldStore)))
}
const setPmSession = (data) => {
  oldStore.pm.session = data
  localStorage.setItem('app', encrypt(JSON.stringify(oldStore)))
}
const setProject = (data) => {
  oldStore.publicInformation.project = data
  localStorage.setItem('app', encrypt(JSON.stringify(oldStore)))
}
const commit = (key, data) => {
  switch (key) {
    case 'authentication':
      setAuth(data)
      break
    case 'resetState':
      resetStore()
      break
    case 'setLogin':
      break
    case 'tryLogin':
      tryLogin(data)
      break
    case 'setToken':
      setToken(data)
      break
    case 'setDisclaimer':
      setDisclaimer(data)
      break
    case 'setName':
      setName(data)
      break
    case 'setTicketSession':
      setTicketSession(data)
      break
    case 'setPmSession':
      setPmSession(data)
      break
    case 'setProject':
      setProject(data)
      break
    default:
      console.log('default')
  }
}

const store = decrypt(localStorage.getItem('app'))
export default {
  ...store,
  commit
}