var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "breadcrumbs" },
    _vm._l(_vm.data, function(item, i) {
      return _c("span", { key: i }, [
        (i > 0) | (i === _vm.data.length - 1 && i > 0)
          ? _c("span", { staticClass: "arrow" }, [_vm._v(" > ")])
          : _vm._e(),
        _vm._v(" "),
        _c("a", { staticClass: "link", attrs: { href: item.link } }, [
          _vm._v(_vm._s(item.label))
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }